.div-camera-capture {
	position: absolute;
}

.wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
}

.video_height {
	height: inherit;
	background: white;
}

.parent {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 999999;
}

.hide_element {
	display: none;
}

.closeScan {
	position: absolute;
	right: 10%;
	top: 10%;
	width: 50px;
	height: 50px;
	opacity: 0.3;
}
.closeScan:hover {
	opacity: 1;
}
.closeScan:before,
.closeScan:after {
	position: absolute;
	left: 35px;
	content: ' ';
	height: 53px;
	width: 5px;
	background-color: #000000;
}
.closeScan:before {
	transform: rotate(45deg);
}
.closeScan:after {
	transform: rotate(-45deg);
}
